<template>
  <div class="d-flex justify-content-center align-center" style="max-width: 100%">
    <img :src="image_url">
  </div>
</template>

<script>
export default {
  data() {
    return {
      image_url: null,
    }
  },
  mounted() {
    this.image_url=this.$route.query.url
  },
}
</script>

<style lang="scss" scoped>

</style>
